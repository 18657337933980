<template>
  <div>
    <b-row v-if="Object.keys(summary).length > 0">
      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <h6 class="title-tools">{{$t('creator.change_requests')}}</h6>
          <h2>{{summary.casting_calls.changes_required}}</h2>
          <p class="text-muted">{{summary.casting_calls.in_review}} {{$t('creator.textInAdmin')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-orders">
          </div>
          <h6 class="title-orders">{{$t('creator.active_casting_calls')}}</h6>
          <h2>{{summary.casting_calls.active}}</h2>
          <p class="text-muted">{{summary.casting_calls.draft}} {{$t('creator.in_draft_mode')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-requests">
          </div>
          <h6 class="title-admin-changes">{{$t('creator.outstanding_pitches')}}</h6>
          <h2>{{summary.casting_calls.outsanding_pitches}}</h2>
          <p class="text-muted">{{$t('creator.working_with')}} {{summary.casting_calls.working_with}} {{ summary.casting_calls.working_with == 1 ? $t('creator.creator') : $t('creator.creators') }}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-earnings">
          </div>
          <h6 class="title-potential">{{$t('creator.current_budget')}}</h6>
          <h2>$ {{numberFormat(summary.casting_calls.budget_casting_calls, true)}}</h2>
          <p class="text-muted">{{$t('creator.in')}} {{summary.casting_calls.working_with}} {{ summary.casting_calls.working_with == 1 ? $t('creator.campaign') : $t('creator.campaigns') }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="info_table.length > 0">
      <b-col class="col-12">
        <b-card class="table-responsive" id="table-searcher-card">
          <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
            <div class="container-mode-views">
              <div class="color-gray-button responsive-button" v-if="checked_castings.length !== 0">
                <span class="ml-2">{{$t('creator.selected_casting_calls')}}: {{checked_castings.length}}</span>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
          
              <b-button variant="flat-secondary" @click="show_sider_filter = true"><b-icon icon="sliders"></b-icon> {{$t('search.filters')}}</b-button>
               
              <b-button 
                :disabled="filters_layout.length === 0"
                variant="flat-secondary"
                class="button-filters"
                @click="getCastingCalls()"
              >
              <feather-icon icon="Trash2Icon"/>
              {{$t('creator.clearFilter')}}
            </b-button>
            </div>
          </b-col>         
        </b-card>
      </b-col>
      <div class="col-12 overflow-scroll">
        <b-table
          :items="format_data"
          id="table-searcher"
          :fields="$t(`creator.table_casting_calls.casting_calls`)"
          head-variant="light"
          v-if="info_table.length > 0 && format_data.length > 0"
          @row-clicked="clickInPitchs"
          :current-page="current_page"
          :per-page="per_page"
          sticky-header="50vh"
          responsive
        >
          <template #head(id)>
            <b-form-checkbox v-model="selected_all"></b-form-checkbox>
          </template>
          <template #cell(id)="data">
            <div class="position-relative">
              <div class="d-flex justify-content-between position-absolute">
                <b-form-checkbox
                  class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                  v-model="checked_castings"
                  :value="data.item.id.uuid"
                  :ref="`checkbox_table_${data.item.id.id}`"
                >
                </b-form-checkbox>
                <h6 class="id-table-searcher" :ref="`id_table_${data.item.id.id}`">{{data.item.id.id}}</h6>
              </div>
            </div>
          </template>

          <template #cell(casting_call)="data">
            <div class="d-flex align-items-center">
              <b-avatar class="mr-1" rounded :src="data.item.casting_call.cover_image" variant="dark"></b-avatar>
              <div>
                <span class="name-transactions ">{{data.item.casting_call.title}}</span>
                <p class="text-muted m-0">
                  {{data.item.casting_call.name_brand}} | {{$t(`creator.type_of_service`).find((type) => type.value === data.item.casting_call.content_type).text}}
                </p>
              </div>
            </div>
          </template>
        
          <template #cell(responses)="data">
            <div class="d-flex align-items-center w-avatares-table" @click="clickInPitchs(data.item)">
              <b-avatar-group 
                size="20px" 
                v-if="data.item.first_pitches.length > 0" 
                :overlap="data.item.first_pitches.length === 1 ? '0.1' : '0.3'">
                <b-avatar
                  v-for="(pitch, index) in data.item.first_pitches" 
                  :key="index"
                  :src="verifyAsset(pitch.user.avatar)"
                  class="avatar-pitches-applied"
                ></b-avatar>
                <b-avatar class="avatar-pitches-applied" v-if="data.item.total_pitches > 3">+{{data.item.total_pitches - 3}}</b-avatar>
              </b-avatar-group>
              <span v-else>--</span>
            </div>
          </template>

          <template #cell(compensation)="data">
            <div class="align-items-center" v-if="data.item.compensation.length > 0">
            $ {{numberFormat(getTotalCompensations(data.item.compensation).total, 2)}} USD
              <b-avatar-group overlap="0.6" variant="dark" size="22">
                <b-avatar class="color-avatar" button v-for="(icn, index) in getTotalCompensations(data.item.compensation).icons" :key="index" :icon="icn"></b-avatar>
              </b-avatar-group>
            </div>
            <div v-else>--</div>
          </template>

          <template #cell(audience)="data">
            <div class="d-flex align-items-center">
              {{$t(`creator.${data.item.audience}`)}}
            </div>
          </template>

          <template #cell(date_delivery)="data">
            <div class="d-flex align-items-center">
              {{data.item.date_delivery ? getDatev2(new Date(data.item.date_delivery)) : '--'}}
            </div>
          </template>

          <template #cell(live_status)="data">
            <b-badge pill :variant="getVariantStatus(data.item.live_status)" :class="getVariantStatus(data.item.live_status)">
            {{$t(`creator.casting_calls_state.${data.item.live_status}`)}}
          </b-badge>
          </template>

          <template #cell(review_status)="data">
            <div class="text-flex">
              {{ data.item.changes_admin ? `${data.item.changes_admin.substr(0, 50)} ${data.item.changes_admin.length > 50 ? '...' : ''} `  : '--'}}
            </div>
          </template>

          <template #cell(actions)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropright
                v-if="data.item.live_status !== 'archived'"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item v-if="data.item.live_status === 'published'" @click="redirectCampaign(data.item.slug)">
                  <b-icon icon="eye" class="mr-50" />
                  <span>{{$t('creator.viewCasting')}}</span>
                </b-dropdown-item>
                <b-dropdown-divider v-if="data.item.live_status === 'published'"></b-dropdown-divider>
                <b-dropdown-item @click="clickInPitchs(data.item)">
                  <b-icon icon="card-list" class="mr-50" />
                  <span>{{$t('creator.viewResponse')}}</span>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="$router.push({name: 'view_casting_call', params: {slug: data.item.slug, is_editing: true}})">
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{$t('lists.edit')}}</span>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="deleteCastingCalls(data.item.id.uuid)">
                  <feather-icon icon="XSquareIcon" class="mr-50" />
                  <span>{{$t('tracking.puasedArchive')}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </div>
      <b-pagination 
        v-model="current_page" 
        :total-rows="format_data.length" 
        :per-page="per_page"
        class="d-flex justify-content-center w-100 mt-1"
      ></b-pagination>
    </b-row>
    <div class="container-loader-searcher loader-table-cards" v-if="!loader_casting_calls">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
    <template v-if="info_table.length === 0 || format_data.length === 0">
      <no-Results
        :title= "$t('creator.noResulstCastingCalls')"
        :subtitle= "$t('creator.msjCreateCampaign')"
        :show_button= "true"
        :text_button="$t('creator.goCreateCampaign')"
        :btn_action="goCreateCampaign"
      />
    </template>
    <sider-filters-casting-calls
      class="open-sidebar-search"
      @close_siderbar_filter="closeSiderFilter"
      :show_sider_filter="show_sider_filter"
      :filters_layout="filters_layout"
      @update_casting="getFormatTable"
      v-if="show_sider_filter"
    />
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
      :texts="{title: $t('creator.msjTitlePremium+Brand'), subtitle: $t('creator.subtitlePremium+Brand')}"
    />
  </div>
</template>

<script>
import {
  BIcon,
  BBadge,
  BSpinner,
  BCol,
  BRow,
  BCard,
  BTable,
  BAvatar,
  BAvatarGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BDropdownDivider,
  BPagination,
} from 'bootstrap-vue';
import { getUserData } from '@/libs/utils/user';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import casting_calls_services from '@/services/casting_calls';
import siderFiltersCastingCalls from "@/views/pages/proposals/stepper/siderFiltersCastingCalls.vue";
import { numberFormat} from '@/libs/utils/formats'
import { verifyAsset } from '@/libs/utils/urls';
import { getDatev2 } from "@/libs/utils/times";


export default {
  name: 'castingCallsTable',
  components: {
    BIcon,
    siderFiltersCastingCalls,
    BAvatarGroup,
    BPagination,
    BBadge,
    BSpinner,
    BButton,
    BCard,
    BCol,
    BRow,
    BTable,
    BFormCheckbox,
    BAvatar,
    BDropdownDivider,
    BDropdown,
    BDropdownItem,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  props: {
    summary: {
      type: Object,
    }
  },
  data() {
    return {
      getDatev2,
      verifyAsset,
      numberFormat,
      update_modal: false,
      open_modal_plans_group: false,
      user: getUserData(),
      format_data: [],
      filters_layout: [],
      loader_casting_calls: false,
      selected_all: null,
      checked_castings: [],
      info_table: [],
      show_sider_filter: false,
      cleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
      },
      pitches_applied: [],
      is_click_pitches: false,
      current_page: 1,
      per_page: 10
    }
  },
  created() {
    this.getCastingCalls();
  },
  methods: {
    redirectCampaign(slug) {
      const url = this.$router.resolve({ name: 'view_casting_call', params: { slug } });
      window.open(url.href, '_blank');
    },
    goCreateCampaign() {
      if (this.user.current_plan.create_casting_call) {
        this.$router.push({ name: 'steps_casting_call', params: { step: 'select-service' } });
      } else {
        this.open_modal_plans_group = true;
        this.update_modal = !this.update_modal;
      }
    },
    closeSiderFilter() {
      this.show_sider_filter = false
    },
    getTotalCompensations(compensations) {
      let sum = 0
      const icons = [];
      compensations.forEach((item) => {
        sum += parseInt(item.amount);
        icons.push(this.$t('creator.compensation_type').find((c) => c.value === item.type_compensation).icon)
      });
      return {total: sum, icons}
    },
    getVariantStatus(status) {
      if (status === 'in_review' || status === 'changes_required') return 'light-warning'
      if (status === 'published') return 'light-success'
      if (status === 'archived' || status === 'unpublished') return 'light-danger'
      return 'light-secondary';
    },
    clickInPitchs(casting_call) {
      this.is_click_pitches = true;
      setTimeout(() => {
        this.$router.push({name: 'pitchs_applied', params: {slug: casting_call.slug}})
      }, 500)
    },
    getCastingCalls() {
      const query = {};
      this.filters_layout = []
      casting_calls_services.getCastingCallsforUser(query).then((response) => {
        this.info_table = response.results;
        this.loader_casting_calls = true;
        this.getFormatTable(this.info_table);
      });
    },
    getFormatTable(castings, filters) {
      if (filters !== undefined) {
        this.filters_layout = filters
      }
      this.format_data = []
      castings.map((item, index) => {
        this.format_data.push(
          {
            id: {id: index + 1, uuid: item.uuid},
            casting_call: {
              title: item.title,
              cover_image: item.cover_url ? verifyAsset(item.cover_url) : verifyAsset(item.cover_image),
              content_type: item.content_type,
              name_brand: item.brand.name
            },
            live_status: item.status,
            compensation: item.compensations,
            audience: item.visibility,
            changes_admin: item.changes_admin,
            date_delivery: item.date_delivery,
            slug: item.slug,
            first_pitches: item.first_pitches,
            total_pitches: item.pitches
          }
        );
      });
    },
    rowTableClick(data) {
      if (!this.is_click_pitches) {
        this.$router.push({name: 'view_casting_call', params: {slug: data.slug}})
      }
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-searcher');
            }
            if (idTable) idTable.classList.add('d-none');
          });
        }
      } else {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-searcher');
            }            
            if (idTable) idTable.classList.remove('d-none');
          });
        }
      }
    },
    checkedAll() {
      this.checked_castings = [];
      let in_array = 0;
      this.info_table.forEach((item, index) => {
        if ((in_array < this.per_page) && (index <= (this.per_page * this.current_page) && index >= (this.per_page * (this.current_page - 1)))) {
          this.checked_castings.push(item.uuid)
          in_array += 1
        }
      });
    },
    deleteCastingCalls(casting_call_uuid) {
      this.$swal({
        title: this.$t('creator.delete_casting_call_title_paused'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('creator.confirm_button_delete_paused'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          casting_calls_services.deleteCastingCall(casting_call_uuid).then(() => {
            const new_info = []
            this.info_table.map((info) => {
              if (info.uuid === casting_call_uuid) info.status = 'archived'
              new_info.push(info)
            });
            this.info_table = new_info;
            this.getFormatTable(this.info_table);
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: "top-right",
                props: {
                  title: this.$t('creator.deleted_pitches'),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: this.$t('creator.deleted_casting_call_text'),
                }
              })
            })
          });
        }
      });
    }
  },
  watch: {
    checked_castings(val) {
      this.checkCheckboxClasses(val);
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_castings = [];
    },
    current_page() {
      this.selected_all = null;
    }
  }
}
</script>
<style scoped>
.text-flex {
  text-wrap: nowrap;
}
  .color-avatar{
    background-color: #e8e8e8 !important;
    color: #82868b !important;
  }
  .ml-03 {
    margin-left: 0.3em;
  }
  .mb-05 {
    margin-bottom: 0.5em;
  }
  .budget-input-sign {
    position: absolute;
    left: 1em;
    top: 0em;
    color: gray;
  }
  .close-sidebar-icon:hover {
    background-color: rgba(219, 219, 219, 0.5);
    border-radius: 50%;
    cursor: pointer;
  }
  .avatar-pitches-applied {
    height: 29px !important;
    width: 29px !important;
  }
  .w-avatares-table {
    width: 100px;
  }
</style>
<style>
  .button-filters {
  margin-left: 5px;
  }
  .button-filters:focus {
  margin-left: 5px;
  background-color: transparent !important;
  }
  .sidebar-pitches-filter > .b-sidebar {
    width: 400px !important;
  }
 .container-loader-searcher {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255);
    z-index: 1000;
    right: 5px;
  }
  .loader-table-cards {
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 14em;
    height: 6em;
    width: 6em;
    color: #acacac;
  }
  .background-left-proposal {
    width: 10px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0px;
  }
  .title-tools{
    font-weight: bolder;
    color: #1ABC9C;
  }
  .title-orders{
    font-weight: bolder;
    color: #9B59B6;
  }
  .title-admin-changes{
    font-weight: bolder;
    color: #D68910;
  }
  .title-potential{
    font-weight: bolder;
    color: #EC7063;
  }
  .default-color-background-tools {
    background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
  }
  .default-color-background-orders {
    background: linear-gradient(180deg, #ef98ff 0, #bf83ff 25%, #8f6cd8 50%, #6355b0 75%, #3d3f8b 100%);
  }
  .default-color-background-requests {
    background: radial-gradient(circle at 50% -20.71%, #e7e14a 0, #e4cf42 12.5%, #dcbc3c 25%, #d2a736 37.5%, #c39231 50%, #b37e2d 62.5%, #a46c29 75%, #965c26 87.5%, #894f24 100%);
  }
  .default-color-background-earnings {
    background: radial-gradient(circle at 50% -20.71%, #ff7e6b 0, #ff6e73 12.5%, #ff5d78 25%, #ff4c7a 37.5%, #f23c78 50%, #d52e74 62.5%, #ba2471 75%, #a21d6e 87.5%, #8d1a6c 100%);
  }
  .light-secondary-v2 {
    background-color: #EFF3F6;
    color: #718096;
    font-weight: 400;
  }
  .avatar-proposal-table {
    margin: 0 1em 0 0;
    border-radius: 1em !important;
  }
  #table-searcher-card .card-body {
    padding: 0;
  }
  .table-responsive {
    overflow: visible;
  }
  #table-searcher td {
    border-bottom: 1px solid #eff3f6;
  }
  #table-searcher tr {
    background-color: white;
  }
  #table-searcher tr:hover {
    background-color: #EFF3F6;
    cursor: pointer;
    transition: all 100ms;
  }
  .thead-light th {
    background-color: white !important;
  }
  .checkbox-table-searcher {
    display: none !important;
  }
  #table-searcher tr:hover .checkbox-table-searcher {
    display: block !important;
  }
  #table-searcher tr:hover .id-table-searcher {
    display: none;
  }
  .mr-05 {
    margin-right: 0.5em;
  }
  .card-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .card-shadow{
    width: 100%;
  }
  .button-spacing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .size-button {
    width: 75%;
    height: 40px;
  }

  .btn-clear {
    width: 20%;
  }

  .btn-clear:focus {
    width: 20%;
    background: transparent !important;
  }
  .open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
  }
  @keyframes sidebar {
    0% {
      transform: translateX(500px)
    }
    100% {
      transform: translateX(0px)
    }
  }
</style>